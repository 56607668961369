import { Router } from 'vue-router'
import { keepAliveStore } from '@/store/modules/keep-alive'

export const setupKeepAlive = (router:Router) => {

  router.beforeEach((to, form, next) => {
    const { addCache } = keepAliveStore()
    if (to.meta.keepAlive) {
      addCache(to.name as string)
    }
    next()
  })
}