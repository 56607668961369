import { useAppStore } from './app'
import { defineStore } from 'pinia'
import { authApi } from '@/api/auth'
import { views } from '../../router'
import { trimStart } from 'lodash-es'
// import { menuApi } from '@/api/system'
import {
  setItem,
  getItem,
  removeItem,
  STORAGE_KEY_TOKEN,
  HISTORY_PAGE,
  TABLE_COLLAPSES,
  sessionKey,
  removeSessionItem
} from '@/utils/storage'
import { router } from '@/router'
import { Model } from '@/types/model'
import { setCookieToken } from '@/utils/cookie'

interface UserState {

  /** 接口调用权限 */
  token: string | null;

  /** 用户信息 */
  user: Model.User | null;
  menus: object[],

  permissions: string[],
}

export const useUserStore = defineStore('User', {
  state: (): UserState => ({
    token: getItem(STORAGE_KEY_TOKEN),
    user: null,
    menus: [],
    permissions: []
  }),

  getters: {
    // 是否登录
    isLogin(): boolean {
      return !!this.token
    },

  },
  actions: {
    // 登录
    async loginSuccess(token: string) {
      setItem(STORAGE_KEY_TOKEN, token)
      this.token = token
      setCookieToken(token)
      await this.afterLogin()
    },

    // 登录后的处理逻辑，统一获得 token 后需要操作的事情，token 有效期间，重新进入系统获取全局配置
    async afterLogin() {
      await Promise.all([
        this.getUserInfo(), // 获取用户信息及全局配置
        // this.getMenuList(), // 获取菜单列表
        // useAppStore().refreshReference(),
      ])
    },

    // 获取用户信息
    async getUserInfo() {
      await useAppStore().refreshReference()
      const res = await authApi.getUserInfo()

      this.user = res.user
      this.permissions = res.permissions
      this.setMenu(res.routers)
      // useAppStore().refreshTimerData(true) // 登录时开启消息定时器
      // this.user = res.admin
      // this.group = res.group


      // 使用本地菜单
      // const menu = this.getLocalMenu()
      // this.getMenuList(menu)
    },

    setMenu(data: any[]) {
      this.menus = buildAsyncRoutes(data)
    },


    // 登出
    async logout(requestApi?: boolean, refererPath?: string) {
      requestApi && (await authApi.logout())
      await useAppStore().stopTimer() // 关闭消息定时器
      this.token = null
      removeItem(STORAGE_KEY_TOKEN)
      // 退出登录清除历史标签页记录
      getItem(HISTORY_PAGE) && removeItem(HISTORY_PAGE)
      removeItem(TABLE_COLLAPSES) // 清除gridTable组件缓存的伸展状态
      removeSessionItem(sessionKey.ANTENTDANCE_ERPORT_PROJECT_ID) // 清除考勤模块缓存的项目ID
      router.replace({
        name: 'login',
        query: {
          refererUrl: refererPath ? encodeURIComponent(refererPath) : '',
        },
      })
    },

    hasPermission(requirePermission: Permission | undefined): boolean {
      return this.checkAuth(requirePermission)
    },
    // 验证权限
    checkAuth(name: string | string[]) {
      if (!name) {
        return true
      }
      let isCan = false

      if (Array.isArray(name)) {
        for (let key in name) {
          isCan = this.permissions.includes(name[key])
          if (isCan) {
            break
          }
        }
        return isCan

      } else {
        return this.permissions.includes(name)
      }
    },
  },
})

// 生成路由
const buildAsyncRoutes = (routers: any[], menus: any[] = [], parentName = '', parentPath = '') => {
  routers.forEach(item => {
    const path = `/${trimStart(item.path, '/')}`
    let menu = {
      path: parentPath ? parentPath + path.split('?').shift() : path.split('?').shift(),
      // name: item.path,
      name: item.permission,
      component: views[`../views/${item.component}.vue`],
      meta: {
        parentName,
        url: item.path,
        title: item.name,
        hideInMenu: item.hidden_menu == 1,
        icon: item.icon,
        collapsed: item.collapsed,
        keepAlive: item.keep_alive,
        name: item.permission,
        toPath: item.toPath
      },
      children: [],
      props: route => Object.assign({}, route.query, route.params),
    }
    if (item.children) {
      buildAsyncRoutes(item.children, menu.children, item.permission, menu.path)
    }
    router.addRoute('root', menu as RouteRecordRaw)
    menus.push(menu)
  })
  return menus
}

