//本地枚举请到reference-creator.js中添加
export const localReference = {
    followStatusLocalEnum: [{"value":0,"label":"初步沟通","color":"orange","key":"INIT_TALK"},{"value":1,"label":"推荐","color":"green","key":"RECOMMEND"},{"value":2,"label":"客户初面","color":"blue","key":"FIRST_VIEW"},{"value":3,"label":"复试","color":"blue","key":"SECOND_TEST"},{"value":4,"label":"谈薪","color":"blue","key":"TALK_SALARY"},{"value":5,"label":"背调","color":"orange","key":"BACK_CHECK"},{"value":6,"label":"offer","color":"green","key":"OFFER"},{"value":7,"label":"离职申请","color":"red","key":"QUIT"},{"value":8,"label":"入职","color":"green","key":"ENTRY"},{"value":9,"label":"试用期","color":"green","key":"TRY_TIME"},{"value":10,"label":"过保","color":"orange","key":"GUO_BAO"},{"value":-1,"label":"淘汰","color":"red","key":"TAO_TAI"},{"value":11,"label":"飞单","color":"red","key":"FEI_DAN"}],
    frontendCommissionLocalEnum: [{"value":1,"label":"一口价","key":"ONE_MONEY"},{"value":2,"label":"按年薪","key":"YEAR_MONEY"},{"value":3,"label":"按月薪","key":"MONTH_MONEY"}],
    frontendJobStatusLocalEnum: [{"value":0,"label":"未开始","key":"NO_START","color":"red"},{"value":1,"label":"进行中","key":"DOING","color":"orange"},{"value":2,"label":"已完成","key":"COMPLETE","color":"green"}],
    frontendAccountStatusLocalEnum: [{"value":0,"label":"冻结","color":"red","key":"FREEZE"},{"value":1,"label":"激活","color":"green","key":"ACTIVE"}],
    frontendAccountEmailTypeLocalEnum: [{"value":"163","label":"163","key":"163"},{"value":"126","label":"126","key":"126"},{"value":"tencent","label":"tencent","key":"TENCENT"},{"value":"qq","label":"qq","key":"QQ"}],
    moneyLocalEnum: [{"value":0,"label":"5W","key":"WU_WAN"},{"value":1,"label":"6W","key":"LIU_WAN"},{"value":2,"label":"7W","key":"QI_WAN"},{"value":3,"label":"8W","key":"BA_WAN"},{"value":4,"label":"9W","key":"JIU_WAN"},{"value":5,"label":"10W","key":"SHI_WAN"},{"value":6,"label":"11W","key":"SHIYI_WAN"},{"value":7,"label":"12W","key":"SHIER_WAN"},{"value":8,"label":"13W","key":"SHISAN_WAN"},{"value":9,"label":"14W","key":"SHISI_WAN"},{"value":10,"label":"15W","key":"SHIWU_WAN"},{"value":11,"label":"16W","key":"SHILIU_WAN"}],
    salaryTypeLocalEnum: [{"value":0,"label":"面议","key":"FACE_TYPE"},{"value":1,"label":"月薪","key":"MONTH_SALARY_TYPE"},{"value":2,"label":"年薪","key":"YEAR_SALARY_TYPE"}],
    sexLocalEnum: [{"value":0,"label":"不限","key":"NO_LIMIT"},{"value":1,"label":"男","key":"MAN"},{"value":2,"label":"女","key":"WOMAN"}],
    descSexLocalEnum: [{"value":0,"label":"未知","key":"UNKNOWN"},{"value":1,"label":"男","key":"MAN"},{"value":2,"label":"女","key":"WOMAN"}],
    expLocalEnum: [{"value":1,"label":"1年","key":"ONE_YEAR"},{"value":2,"label":"2年","key":"TWO_YEAR"},{"value":3,"label":"3年","key":"THREE_YEAR"},{"value":4,"label":"4年","key":"FOUR_YEAR"},{"value":5,"label":"5年","key":"FIVE_YEAR"},{"value":6,"label":"6年","key":"SIX_YEAR"}],
    marriageLocalEnum: [{"value":0,"label":"未知","key":"UNKNOWN"},{"value":1,"label":"未婚","key":"NO_MARRIAGE"},{"value":2,"label":"已婚","key":"MARRIAGED"},{"value":3,"label":"离异","key":"LEAVE_MARRIAGED"},{"value":4,"label":"丧偶","key":"LOSE"}],
    statusLocalEnum: [{"value":0,"label":"在职/无求职打算","key":"STAY_NO_INTEND"},{"value":1,"label":"在职/看看新机会","key":"STAY_LOOK_INTEND"},{"value":2,"label":"离职/正在找工作","key":"LEAVE_FINDING"}],
    levelLocalEnum: [{"value":0,"label":"一般","key":"YI_BAN"},{"value":1,"label":"良好","key":"LIANG_HAO"},{"value":2,"label":"熟练","key":"SHU_LIAN"},{"value":3,"label":"精通","key":"JING_TONG"}],
    positionSalaryTypeLocalEnum: [{"value":1,"label":"长白班","key":"LONG"},{"value":2,"label":"两班倒","key":"TWO"},{"value":3,"label":"正式工","key":"NORMAL"},{"value":4,"label":"小时工","key":"HOUR"}],
    yesOrNoEnum: [{"value":1,"label":"是","key":"YES"},{"value":0,"label":"否","key":"NO"}],
    reportDataType: [{"value":"my","label":"仅本人","key":"my"},{"value":"dep","label":"部门及下属","key":"dep"},{"value":"all","label":"全部数据","key":"all"},{"value":"diy","label":"自定义","key":"diy"}],
  }