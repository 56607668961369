import type { CustomRouteRecordRaw } from 'vue-router'
import { useSiteConfig } from '@/hooks/site-config'
import BackendLayoutSide from '@/views/layouts/backend-layout-side.vue'

// import { exampleRoutes } from './example'
import { workbenchRoutes } from './workbench'
import { resumeRoutes } from './resume'
import { systemRoutes } from './system'
import { jobRoutes } from './job'
import { companyRoutes } from './company'
import { callListRoutes } from './callList'
import { mediaRoutes } from './media'
import { reportRoutes } from './report'
import { helpRoutes } from './help'
import { personalRoutes } from './personal'
import { douyinRoutes } from '@/router/routes/douyin'
const { backendPrefix } = useSiteConfig

export const appRoutes: CustomRouteRecordRaw[] = [
  // customerRoutes,
  workbenchRoutes,
  resumeRoutes,
  jobRoutes,
  callListRoutes,
  mediaRoutes,
  companyRoutes,
  reportRoutes,
  systemRoutes,
  helpRoutes,
  personalRoutes,
  douyinRoutes
  // exampleRoutes
]

const routes: CustomRouteRecordRaw[] = [
  {
    path: `${backendPrefix}/login`,
    name: 'login',
    component: () => import('@/views/auth/login/login.vue'),
    meta: {
      title: '账号登录',
      noNeedAuth: true,
      hideHistoryPage: true,
    },
  },
  {
    path: `${backendPrefix}/login-loading`,
    name: 'loginLoading',
    component: () => import('@/views/auth/login-loading.vue'),
    meta: {
      title: '授权登录中',
      noNeedAuth: true,
      hideHistoryPage: true,

    },
  },
  {
    path: backendPrefix,
    name: 'root',
    component: BackendLayoutSide,
    // 必须时路径方式，路由名重定向报错
    redirect: `${backendPrefix}/workbench`,
    // children: appRoutes,
    children: [],
  },
  {
    path: '/:patchMatch(.*)*',
    name: 'notFound',
    // redirect: { name: 'root' },
    component: () => import('@/views/404.vue'),
    meta: {
      title: '页面不存在',
      noNeedAuth: true,
    },
  },
]

// 获取父路由的名字
function setParentName(routeList, parentNameList) {
  routeList.forEach(route => {
    route.meta = route.meta || {}
    route.meta.parentName = parentNameList
    route.children?.length
      && setParentName(route.children, [...parentNameList, route.name])
  })
}
setParentName(routes, [])

export default routes

