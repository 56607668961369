<template>
  <a-modal v-model:visible="showDiyModal" title="选择" width="680px" :closable="false" @cancel="handleCancel" @ok="handleOk">
    <div>
      <div class="flex">
        <div class="flex-1 px-2 borderd-r">
          <a-input-search v-model:value="kw" allow-clear placeholder="关键字搜索" @change="onSearch" @search="reload" />
          <div>
            <a-tabs v-model:activeKey="activeKey">
              <a-tab-pane key="users" tab="选员工">
                <div class="h-[300px] overflow-auto relative">
                  <div v-if="loading" class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <a-spin />
                  </div>
                  <template v-else-if="users.length > 0">
                    <a-row v-for="item in users" class="my-2">
                      <a-checkbox :checked="selectedUsers.some((s) => s.id === item.id)" @change="(e) => toggleSelectUser(e, item)">
                        <div>
                          <a-avatar :src="item.avatar"></a-avatar>
                          {{ item.name }}
                        </div>
                      </a-checkbox>
                    </a-row>
                  </template>
                  <a-empty v-else :image="simpleImage" />
                </div>
              </a-tab-pane>
              <a-tab-pane key="departments" tab="选部门">
                <div class="h-[300px] overflow-auto relative">
                  <div v-if="loading" class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <a-spin />
                  </div>
                  <template v-else-if="departments.length > 0">
                    <a-tree
                      :tree-data="departments"
                      :checkedKeys="selectedDepIds"
                      :fieldNames="{ key: 'id' }"
                      :checkStrictly="true"
                      :autoExpandParent="true"
                      checkable
                      @check="toggleSelectDep"
                    ></a-tree>
                  </template>
                  <a-empty v-else :image="simpleImage" />
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
        <div class="flex flex-col flex-1 px-2">
          <div class="flex-1 overflow-auto">
            <div class="flex justify-between w-full">
              <div>已选：{{ selectedUsers.length }}个员工</div>
              <div><a-button type="link" size="small" :disabled="selectedUsers.length <= 0" @click="clearUser">清除</a-button></div>
            </div>
            <div class="mt-1">
              <a-tag v-for="item in selectedUsers" closable @close.prevent="() => delUser(item)">{{ item.name }}</a-tag>
            </div>
          </div>
          <div class="my-1 borderd-b"></div>
          <div class="flex-1 overflow-auto">
            <div class="flex justify-between w-full">
              <div>已选：{{ selectedDeps.length }}个部门</div>
              <div><a-button type="link" size="small" :disabled="selectedDeps.length <= 0" @click="clearDep">清除</a-button></div>
            </div>
            <div class="relative mt-1 overflow-auto">
              <a-tag v-for="item in selectedDeps" closable @close.prevent="() => delDep(item)">{{ item.title }}</a-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
import { Empty } from 'ant-design-vue'
import { CheckboxChangeEvent } from 'ant-design-vue/es/_util/EventInterface'
import { debounce } from 'lodash-es'
import { getReferenceByType } from '@/api/global'
import { useMessage } from '@/hooks/message'
import { Model } from '@/types/model'
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE

interface OptionItem {
  title: string
  id: number | string
  parent_id: number
  depth: number
  children?: OptionItem[]
}
const loading = ref(false)
const kw = ref('')
const activeKey = ref('users')
const showDiyModal = ref(false)
const users = ref<Model.User[]>([])
const departments = ref<Model.Department[]>([])
const selectedUsers = ref<Model.User[]>([])
const selectedDeps = ref<Model.Department[]>([])

const selectedDepIds = computed(() => selectedDeps.value.map(i => i.id))

const onSearch = debounce(reload, 150)
watch(() => activeKey.value, reload, { immediate: true })
async function reload() {
  loading.value = true
  const rs = await getReferenceByType(activeKey.value, { name: kw.value })
  switch (activeKey.value) {
    case 'users':
      users.value = rs.data
      break
    case 'departments':
      const departmentTree = tree(rs)
      departments.value = departmentTree
      break
  }
  loading.value = false
}

function tree(rawOptions: OptionItem[], parentOption?: OptionItem) {
  let options: OptionItem[]
  if (!parentOption) {
    // const minDepth = Math.min(...rawOptions.map((r) => r?.path && r?.path.split(',').length-1))
    // options = rawOptions.filter((r) => r?.path.split(',').length-1 === minDepth)
    return rawOptions.map(item => tree(rawOptions, item))
  } else {
    options = rawOptions.filter(r => r.parent_id === parentOption.id)
    if (!options || options.length <= 0) {
      return parentOption
    }
    const children = options.map(item => tree(rawOptions, item))
    return {
      ...parentOption,
      children,
    }
  }
}

function toggleSelectUser(e: CheckboxChangeEvent, item: Model.User) {
  const { checked } = e.target
  const index = selectedUsers.value.findIndex((s) => s.id === item.id)
  if (checked && index < 0) {
    selectedUsers.value.push(item)
    return
  }
  if (!checked && index >= 0) {
    selectedUsers.value.splice(index, 1)
  }
}

function toggleSelectDep(_, e) {
  const { checked, node } = e
  const item = node.dataRef
  const index = selectedDeps.value.findIndex((s) => s.id === item.id)
  if (checked && index < 0) {
    selectedDeps.value.push(item)
    return
  }
  if (!checked && index >= 0) {
    selectedDeps.value.splice(index, 1)
  }
}

function delUser(item: Model.User) {
  const index = selectedUsers.value.findIndex((s) => s.id === item.id)
  if (index >= 0) {
    selectedUsers.value.splice(index, 1)
    console.log('selectedUsers', selectedUsers.value)
    return
  }
}

function delDep(item: Model.Department) {
  const index = selectedDeps.value.findIndex((s) => s.id === item.id)
  if (index >= 0) {
    selectedDeps.value.splice(index, 1)
    return
  }
}

function clearUser() {
  selectedUsers.value = []
}

function clearDep() {
  selectedDeps.value = []
}

function open() {
  showDiyModal.value = true
}

function close() {
  showDiyModal.value = false
}

function handleCancel() {
  clearUser()
  clearDep()
}

function handleOk() {
  if (selectedUsers.value.length <= 0 && selectedDeps.value.length <= 0) {
    useMessage.error('请选择用户或者部门')
    return
  }
  emits('ok', { users: toRaw(unref(selectedUsers)), departments: toRaw(unref(selectedDeps)) })
}

const emits = defineEmits(['ok'])
defineExpose({ open, close })
</script>
