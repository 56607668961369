import { ExtractPropTypes, PropType } from 'vue'
import type { UploadProps as AUploadProps, UploadFile } from 'ant-design-vue/es/upload'
import { ButtonType } from 'ant-design-vue/es/button'

export const uploadProps = {
  // 文件最大大小
  maxSize: {
    type: Number,
    default: 2,
  },
  // 最大文件数
  maxNum: {
    type: Number,
    default: 1,
  },
  // 是否可以拖拽上传
  isDragger: {
    type: Boolean,
    default: false
  },
  value: {
    type: [Object as PropType<string[]>, String],
    default: () => [],
    // type: [
    //   Array as PropType<string[]>,
    //   String
    // ],
    // default: () => [],
  },
  autoUpload: {
    type: Boolean,
    default: true
  },
  // 如果需要id而不是链接
  valueIsId: {
    type: Boolean,
    default: false,
  },
  // // 如果需要整个file而不是链接
  // valueIsFile: {
  //   type: Object,
  //   default: {},
  // },

  accept: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  onOriginResponseValue: {
    type: Function as PropType<(originFiles: UploadFile[]) => void>
  },
  acceptType: {
    type: String as PropType<'img' | 'file' | 'zip' | 'doc' | 'all'>,
    default: 'file',
  },

  buttonType: {
    type: String as PropType<ButtonType>,
  },
  ghost: {
    type: Boolean,
    default: false
  },

  // 上传组件提示信息
  remindInfo: {
    type: String
  },
  // 上传地址
  uploadUrl: {
    type: String,
    default: '/upload-img'
  },
  uploadBtnText: {
    type: String,
    default: '上传'
  },
  handleChangeFn: {
    type: Function as PropType<(data: any[], targetData:any) => void>
  },
  // 是否显示上传列表
  hideUploadList: {
    type: Boolean,
    default: false
  }
}

export type UploadProps = Partial<ExtractPropTypes<typeof uploadProps>> & AUploadProps
