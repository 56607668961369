
/**
 * 修复低版本浏览器不支持数组最新的语法问题
 */
type FixType = 'at' | 'all'
export const fixArray = (type:FixType = 'all') => {

  /* Array.prototype.at() */
  if (!Array.prototype.at && (type === 'at' || type === 'all')) {
    Array.prototype.at = function(index) {
      if (index >= 0) {
        return this[index]
      } else {
        return this[this.length + index]
      }
    }
  }
}