<template>
  <a-form-item-rest>
    <quick-date-picker class="w-[200px]" :value="value" @change="handleChange"/>

    <a-range-picker v-if="props.value === 'custom'" :value="value" :allowClear="false" inputReadOnly class="ml-2 w-[260px]" valueFormat="YYYY-MM-DD" @change="changeRange"/>
  </a-form-item-rest>
</template>

<script lang="ts" setup name="custom-date-select">
import { PropType } from 'vue'

type EmitEvents = {
  (e:'update:value', value):void
}
const emits = defineEmits<EmitEvents>()
const props = defineProps({
  value: {
    type: String as PropType<string>,
    default: 'day',
  },
})
const showValue = ref(props.value)

function handleChange(value) {
  // showValue.value = value
  emits('update:value', value)
}

handleChange(props.value)

function changeRange(value) {
   value = `${value[0]}至${value[1]}`
  emits('update:value', value)
}
</script>
