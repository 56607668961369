<template>
  <div class="w-full">
    <a-upload-dragger
      v-bind="bindAttrs"
      :before-upload="handleBeforeUpload"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <basic-icon
          name="icon-upload_black_24dp"
          class="text-4xl"
        />
      </p>
      <p class="ant-upload-text">
        {{ props.uploadBtnText ?? '点击或拖拽文件到此上传' }}
      </p>
    </a-upload-dragger>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRaw, unref, useAttrs, useSlots } from 'vue'
import { useMessage } from '@/hooks/message'
import { useEnv } from '@/hooks/env'
import { useUserStore } from '@/store'
import { pick, omit, isObject, isArray, isFunction } from 'lodash-es'
import type { UploadFile, UploadProps } from 'ant-design-vue'
import { uploadDraggerProps } from './props'
import { getFileName } from '@/utils/qs'
import imageCompression from 'browser-image-compression'
import { FileType } from 'ant-design-vue/es/upload/interface'

const attrs = useAttrs()
const slots = useSlots()
type EmitEvents = {
  (e: 'update:value', filePaths: Array<string> | string):void,
  (e: 'OriginResponseValue', files: Array<any>): void,
}
const emits = defineEmits<EmitEvents>()
const props = defineProps(uploadDraggerProps)
// 文件路径数组
const filePaths = ref<string[]>([])
const files = ref<UploadProps['fileList']>([])

// upload 组件值，设置该值，使得 upload 组件为受控组件。上传的状态及图片的显示，由 files 控制
// 目前格式是字符串数组图片链接
onMounted(() => {
  if (isArray(props.value)) {
    files.value = props.value.map(fileUrl => {
      const fileName = getFileName(fileUrl)
      return {
        uid: fileName,
        name: fileName,
        status: 'done',
        url: fileUrl,
      }
    })
  } else {
    const fileName = getFileName(props.value)
    files.value = [
      {
        uid: fileName,
        name: fileName,
        status: 'done',
        url: props.value,
      }
    ]
  }
})

watch(() => props.value, val => {
  const urlList: string[] = []
  let vals:any = []
  if (isArray(val)) {
    vals = val?.filter(item => isObject(item)).map((item: any) => {
      const fileName = getFileName(item.img)
      urlList.push(item.img)
      return {
        uid: fileName,
        name: fileName,
        status: 'done',
        url: item.img,
      }
    })
  }
  if (isArray(urlList) && urlList.length > 0) {
    emits('update:value', attrs.multiple ? urlList : urlList[0])
  }
  if (vals?.length > 0) {
    files.value = vals
  }
  // 重置预览图片
  if (isArray(val) && val.length === 0) {
    files.value = []
  }
})


// 允许上传的文件类型
const getAccept = computed(() => {
  if (props.accept.length) {
    return props.accept
  }
  if (props.acceptType === 'img') {
    return ['jpg', 'jpeg', 'png']
  }
  if (props.acceptType === 'zip') {
    return ['zip']
  }
  if (props.acceptType === 'doc') {
    return ['doc', 'docx']
  }
  if (props.acceptType === 'all') {
    return ['jpg', 'jpeg', 'png', 'zip', 'doc', 'docx', 'xls', 'xlsx', 'pdf']
  }
  return ['xls', 'xlsx', 'pdf']
})

// 获取上传接口调用的请求头
const getHeaders = computed(() => ({
  ...pick(attrs, 'headers'),
  Authorization: `Bearer ${useUserStore().token}`,
}))

const getParams = computed(() => ({
  ...pick(attrs, 'data'),
  type: props.acceptType,
}))

const bindAttrs = computed(() => ({
  fileList: unref(files),
  // action: useEnv.uploadApiUrl,
  action: useEnv.baseApiUrl + props.uploadUrl,
  headers: unref(getHeaders),
  data: unref(getParams),
  beforeUpload: file => file,
  accept: unref(getAccept)
    .map(i => (i.startsWith('.') ? i : `.${i}`))
    .join(','),
  ...(props.acceptType === 'img' ? { 'list-type': 'picture-card' } : {}),
  ...omit(attrs, ['headers', 'data']),
}))
// 上传前拦截
async function handleBeforeUpload(file: FileType, FileList: FileType[]) {
  const { size, name } = file
  const { maxSize, maxNum } = props
  if (maxSize && maxSize * 1024 * 1024 < size) {
    useMessage.error(`只能上传不超过${maxSize}M的文件`)
    return false
  }
  if (maxNum && maxNum < FileList.length) {
    useMessage.error(`最多上传${maxNum}个文件`)
    return false
  }

  const accept = unref(getAccept)
  if (accept.length > 0 && !new RegExp(`\\.(${accept.join('|')})$`, 'i').test(name)) {
    useMessage.error(`只能上传${accept.join(',')}格式的文件`)
    return false
  }
  if (props.acceptType === 'img') {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      initialQuality: 0.6
    }
    const compressedFile = await imageCompression(file, options)
    return Promise.resolve(compressedFile)
  }
  return props.autoUpload
}

// 上传完成，数据整理
function handleChange({ file, fileList }: { file: UploadFile, fileList: UploadFile[] }) {
  const doneFileList = toRaw(fileList)
    .filter(f => f.status === 'done')
  if (file?.size && file?.size > props?.maxSize * 1024 * 1024) {
    return
  }

  files.value = fileList // 设置为受控组件，需要将 uploading 状态的 file 同步到 files 中，使的上传功能继续执行，才能触发 done 等上传结束事件
  if (file.status !== 'done' && file.status !== 'removed' || files.value.length !== doneFileList.length) {
    return
  }

  filePaths.value = doneFileList
    .map(f => {
      if (f.response) { // 新上传的文件
        return f.response.data.url ?? f.response.data.path
      } else { // 数据回填的文件
        return new URL(f.url!).pathname
      }
    })
  const fileIds = doneFileList
    .map(f => f.response.data.id)
  const originResponseValue:UploadFile[] = doneFileList
    .map(f => f?.response?.data)
  if (props.valueIsId) {
    emits('update:value', attrs.multiple ? fileIds : fileIds[0])
  } else {
    emits('update:value', attrs.multiple ? filePaths.value : filePaths.value[0])
  }
  if (isFunction(props.handleChangeFn) && file.status === 'done') {
    props.handleChangeFn(doneFileList, file)
  }
  emits('OriginResponseValue', originResponseValue)
}
</script>
