<style lang="less" scoped>
@nav-size-height: 60px;
@layout-max-width: 1100px;

.layout {
  height: 100vh;
  width: 100%;
}

.layout-navbar {
  padding: 0;
  background-color: #FFFFFF;
  // background-color: #2d8cf0;
}

.layout-sider {
  position: "fixed";
  top: 0;
  bottom: 0;
  left: 0;
  overflow: "auto";
  height: "100vh";

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid #58636B;
    box-sizing: border-box;
    height: 57px;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #001529;
    cursor: pointer;

    &.collapsed {
      background-image: url("@images/logo-simple.png");
      background-size: 86%;
    }
  }
}
</style>

<template>
  <a-layout
    class="layout"
    style="height: 100vh;"
    theme="light"
  >
    <a-layout-sider
      v-model:collapsed="collapsed"
      class="layout-sider"
      collapsible
      :width="180"
      :collapsed-width="48"
    >
      <div :class="{ logo: true, collapsed: collapsed }">
        <img
          class="w-[38px] h-[32px]"
          src="@images/logo-simple.png"
        >
        {{ collapsed?"":"欢创招聘" }}
      </div>
      <!--
          <div class="logo">
            <img
              v-if="!collapsed"
              class="logo-text"
              src="@images/logo-text.png"
            >
            <img
              v-else
              src="@images/logo-simple.png"
              class="logo-collapsed"
            >
          </div> -->
      <Menu />
    </a-layout-sider>


    <a-layout>
      <a-layout-header class="shadow layout-navbar">
        <Header />
      </a-layout-header>
      <a-layout-content class="overflow-auto">
        <Content />
      </a-layout-content>
      <!-- <a-layout-footer>
        <Footer />
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>

<script lang="ts" setup>
import Menu from './components-side/menu.vue'
import Content from './components-side/content.vue'
import Header from './components-side/header.vue'
const collapsed = ref(false)
</script>
