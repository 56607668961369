import { defineStore } from 'pinia'
import { globalApi } from '@/api/global'
import { isNumber, isString } from 'lodash-es'
import { localReference } from '@/types/local-reference'
import { Stoppable, useTimeoutFn } from '@vueuse/core'
import { Model } from '@/types/model'
import { noticeApi } from '@/api/notice'
const referenceColorMap = {
  green: '#00B42A',
  orange: '#FF9A2E',
  red: '#F53F3F',
}

interface GlobalState {

  /** 枚举类型 */
  reference: Nullable<
    Partial<{ [key in Reference.ReferenceKey]: Reference.ReferenceItem[] }>
  >;
  referenceColorMap: typeof referenceColorMap;

  notifications: Model.Notification;
  messages: Model.MessageItem[];
  historyMessageList: Model.MessageItem[],
  unReadMessageList: Model.MessageItem[],
}

let timer: Nullable<Stoppable>

/** app 全局信息 */
export const useAppStore = defineStore('App', {
  state: (): GlobalState => ({
    reference: null,

    /** reference 中配置的颜色转换器 */
    referenceColorMap,

    notifications: {} as Model.Notification,

    // 顶部小铃铛的消息列表
    messages: [],
    historyMessageList: [],
    unReadMessageList: [],
  }),

  actions: {

    /** 获取枚举值详情 */
    getReferenceLabel(
      referenceKey: Reference.ReferenceKey,
      value: number
    ): string {
      const referenceItem = this.getReferenceItems(referenceKey)
      if (!isNumber(value) && !isString(value)) {
        return ''
      }
      return (
        referenceItem.find(item => item.value.toString() === value.toString())
          ?.label || ''
      )
    },

    /** 获取枚举值列表 */
    getReferenceItems(referenceKey: Reference.ReferenceKey): Reference.ReferenceItem[] {
      return this.reference?.[referenceKey] || []
    },

    /** 获取枚举值的值映射列表 */
    getReferenceItemsValueMap(referenceKey: Reference.ReferenceKey): Record<number, Reference.ReferenceItem> {
      let valueMap: Record<number, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        valueMap[item.value] = toRaw(item)
      })
      return valueMap
    },

    /** 获取枚举值的值映射列表 */
    getReferenceItemsLabelMap(referenceKey: Reference.ReferenceKey): Record<string, Reference.ReferenceItem> {
      let labelMap: Record<string, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        labelMap[item.label] = toRaw(item)
      })
      return labelMap
    },

    /** 获取枚举值常量 map */
    getReferenceConstantMap<T extends Reference.ReferenceKey>(referenceKey: T): Reference.ConstantKeyMap[T] {
      let constantMap = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        constantMap[item.key!] = toRaw(item)
      })
      return constantMap as Reference.ConstantKeyMap[T]
    },

    /** 重置枚举值 */
    async refreshReference() {
      // let res = await globalApi.getReference()
      // this.reference = Object.keys(res).reduce((acc, key) => {
      //   acc[key] = res[key].filter(item => item.id && item.name).map(item => ({
      //     value: item.id,
      //     label: item.name,
      //     ...item
      //   }))
      //   return acc
      // }, {})
      // console.log(this.reference, 'reference')

      let res = await globalApi.getGlobalData()
      Object.keys(res).forEach(key => {
        if (Array.isArray(res[key])) {
          res[key]?.forEach(item => {
            item.label = item.label ?? item?.name
            item.value = item.value ?? item?.id
          })
        }
      })
      this.reference = res
      this.reference = Object.assign(localReference, this.reference)

      /** 设置枚举颜色值 */
      // 统一管理颜色
      const greenColor = '#26BF26FF'// 绿色
      const greenBgColor = '#26BF261A'// 绿色背景色
      const redColor = '#EB3C0D'// 红色
      const redBgColor = '#FD5308'// 红色背景色
      const orangeColor = '#FF7D00FF'// 橙色
      const orangeBgColor = '#FF7D001A'// 绿色背景色
      const blueColor = '#2196F3FF'// 蓝色
      const blueBgColor = '#2196F31A'// 蓝色背景色
      const purpleColor = '#8A89CBFF'// 紫色
      const purpleBgColor = '#8A89CB1A'// 紫色背景色

      const frontendAccountStatusLocalEnum = this.getReferenceConstantMap('frontendAccountStatusLocalEnum')
      frontendAccountStatusLocalEnum.ACTIVE.color = greenColor
      frontendAccountStatusLocalEnum.FREEZE.color = redColor

    },

    // 未处理消息
    refreshTimerData(loop?: boolean) {
      noticeApi.getFresh().then(res => {
        this.historyMessageList = res?.list ?? []
        this.unReadMessageList = res?.unreadList ?? []
      })
      // if (loop && import.meta.env.PROD) {
      timer = useTimeoutFn(() => this.refreshTimerData(true), 10000)
      // }
    },

    // 点击消息跳转到对应详情
    clickMessageToDetail(record) {
      let routerName = ''
      let messageParams = {}
      switch (record.messageEventType) {
        // 合同待签署
        case this.getReferenceConstantMap('messageEventEnum').CONTRACT_WAIT_SIGN.value:
          routerName = 'contractList'
          break
        // 员工待入职
        case this.getReferenceConstantMap('messageEventEnum').STAFF_WAIT_ENTRY.value:
          routerName = 'workerToBeEmployed'
          break
        // 员工待转正
        case this.getReferenceConstantMap('messageEventEnum').STAFF_WAIT_WORKER.value:
          routerName = 'workerToBeConfirmed'
          break
        // 合同即将到期
        case this.getReferenceConstantMap('messageEventEnum').CONTRACT_EXPIRE.value:
          routerName = 'contractToBeExpired'
          break
        // 证件即将到期
        case this.getReferenceConstantMap('messageEventEnum').IDCARD_EXPIRE.value:
          routerName = 'idcardToBeExpired'
          break
        // 工资单反馈
        case this.getReferenceConstantMap('messageEventEnum').WAGE_FEEDBACK.value:
          routerName = 'payrollFeedbackList'
          messageParams = { status: 0 }
          break
        // 单据待审批
        case this.getReferenceConstantMap('messageEventEnum').APPROVE_WAIT_CONFIRM.value:
          routerName = 'oaList'
          break
        // 后端考勤异常
        case this.getReferenceConstantMap('messageEventEnum').PC_CLOCK_ABNORMAL.value:
          routerName = ''
          break
        // 员工工资单待确认
        case this.getReferenceConstantMap('messageEventEnum').STAFF_WAGE_WAIT_CONFIRM.value:
          routerName = ''
          break
        // 员工考勤异常提醒
        case this.getReferenceConstantMap('messageEventEnum').STAFF_CLOCK_ABNORMAL.value:
          routerName = ''
          break
        // 员工单据审批通过
        case this.getReferenceConstantMap('messageEventEnum').STAFF_APPROVE_PASS.value:
          routerName = ''
          break
        // 员工单据审批驳回
        case this.getReferenceConstantMap('messageEventEnum').STAFF_APPROVE_REJECT.value:
          routerName = ''
          break
        // 员工单据审批拒绝
        case this.getReferenceConstantMap('messageEventEnum').STAFF_APPROVE_REFUSE.value:
          routerName = ''
          break
        case this.getReferenceConstantMap('messageEventEnum').SEND_WAGE.value:
          routerName = ''
          break
        // 员工退休提醒
        case this.getReferenceConstantMap('messageEventEnum').STAFF_RETIRE_NOTICE.value:
          routerName = 'workerList'
          // 过滤退休的员工
          messageParams = { messageId: record.id }
          break
        default:
          routerName = ''
      }
      // if (routerName) {
      //   // 设置消息已读
      //   await messageApi.singleMessageRead(record.id)
      //   router.push({
      //     name: routerName,
      //     params: messageParams
      //   })
      // }
    },

    // 关闭定时器
    async stopTimer() {
      await timer?.stop()
      timer = null
    },
  },
})
