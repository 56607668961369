import { defineStore } from 'pinia'
import { Model } from '@/types/model'

interface GlobalCacheState {
  globalCacheMap: Model.GlobalCacheMap,
}

/** 全局缓存 */
export const useGlobalCacheStore = defineStore('GlobalCache', {
  state: (): GlobalCacheState => ({
    globalCacheMap: {}
  }),

  actions: {

    // 清理缓存信息
    clearGlobalCacheMap() {
      this.globalCacheMap = {}
    },

    // 删除单个缓存
    removeGlobalCache(key: string) {
      delete this.globalCacheMap[`${key}`]
    },

    // 添加单个缓存
    addGlobalCache(key: string, data: Model.CacheInfo) {
      this.globalCacheMap[`${key}`] = Object.assign({}, this.getGlobalCache(key), data)
    },

    // 获取单个缓存
    getGlobalCache(key: string): Model.CacheInfo {
      return this.globalCacheMap[`${key}`]
    },
  },
})
