import { ExtractPropTypes } from 'vue'
import type { InputNumberProps } from 'ant-design-vue/es/input-number'

export const inputMoneyTipProps = {
  // 双向数据绑定的该组件的值
  value: {
    type: [Number, String],
    // ?? 为什么不可用有 default
  },
  // 右上角的提示文本
  tipText: {
    type: String,
    default: ''
  },
  // 右上角文本样式
  styleObj: {
    type: Object,
    default: false,
  }
}

export type InputMoneyTipProps = Partial<ExtractPropTypes<typeof inputMoneyTipProps>> & InputNumberProps