<template>
  <a-input
    :value="props.value"
    :maxlength="11"
    @update:value="handleChange"
  />
</template>

<script lang="ts" setup name="input-mobile">
import { inputMobileProps } from './props'

const props = defineProps(inputMobileProps)
type EmitEvents = {
  (e:'update:value', value:string):void
}
const emits = defineEmits<EmitEvents>()

function handleChange(value) {
  const Reg = props.phone ? /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/ : /[^0-9]/gi

  const v = value.replace(Reg, '')

  emits('update:value', v)
}
</script>
