import { ExtractPropTypes } from 'vue'
import type { SelectProps } from 'ant-design-vue/es/select'
// type SelectorTypes = keyof Reference.SelectorTypes

export const usersSelectProps = {
  apiParams: {
    type: Object,
    default: () => ({}),
  },
  // 根据状态过滤
  needFilter: {
    type: Boolean,
    default: true
  }
}

export type UsersSelectProps = Partial<ExtractPropTypes<typeof usersSelectProps>> & SelectProps