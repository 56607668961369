<template>
  <custom-api-select
    :api="getDept"
    :api-params="apiParams"
    :immediate="true"
    value-field="id"
    label-field="name"
  />
</template>
<script lang="ts" setup>
import { useAppStore } from '@/store'
import { deptSelectProps } from './props'
const props = defineProps(deptSelectProps)
const { reference } = useAppStore()
const { apiParams } = props
const getDept = () => (new Promise((resolve, reject) => {
  if (reference) {
    resolve(reference.departments)
  } else {
    reject([])
  }
}))
</script>
