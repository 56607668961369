import { request } from '@/utils/request'

/** 通用接口 */
const globalApi = {

  /** 获取枚举类型 */
  getReference: () => request.get('reference'),


  getGlobalData: () => request.get('global-data'),

  // 获取省市区列表以及根据下属
  getAreaData: (params = {}) => request.get('/Area/area/getAreaData', params),

  /** 上传接口 */
  globalUpLoad: (data: any) => request.upload('global/upload', data, { disableThrottling: true }),

}

export { globalApi }

export function getReferenceByType(type, param) {
  return request.get(`reference/${type}`, param)
}

