import { debounce } from 'lodash-es'
import { Modal } from 'ant-design-vue/es'
(() => {

  /* 因版本更新迭代的速度很快，就会引起用户在应用当中，页面点击无反应，其实是打包的js和css的包名称更改，找不到以前的包的缘故。使用window.addEventListener('error')，判断js或css报错时，强制刷新页面，就可以正常使用了。
链接：https://www.jianshu.com/p/bcbacdf4a1f7 */
  const handleListenerError = debounce(eventErr => {
    if (eventErr.srcElement.localName === 'link' || eventErr.srcElement.localName === 'script') {
      Modal.confirm({
        title: '版本更新提示',
        content: '因版本更新，页面需重新载入',
        okText: '重新刷新',
        onOk: () => window.location.reload()
      })
    }
    eventErr.preventDefault()
  }, 2000)

  window.addEventListener('error', handleListenerError, true)
})()