<style lang="less" scoped>
/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
.content:parent {
  padding: 12px 6px !important;
}
</style>

<template>
  <div class="relative">
    <!-- <span
      v-if="notificaitons.length"
      class="bg-red-500 w-[18px] z-0 h-[18px] text-white flex items-center justify-center text-[10px] absolute top-[4px] right-[4px] rounded-full p-[4px]"
    >
      {{ notificaitons.length > 100 ? '99+' : notificaitons.length }}2
    </span> -->
    <div class="z-10 flex items-center h-full">
      <a-dropdown placement="bottom">
        <div
          class="cursor-pointer w-[50px] h-[50px] flex justify-center items-center rounded text-gray-400 hover:text-blue-400 hover:bg-gray-100"
        >
          <a-badge
            :count="unReadMessageList.length"
            :overflow-count="99"
            size="small"
          >
            <BasicIcon
              name="icon-notifications_black_24dp"
              class="mx-2 mt-2 text-2xl text-primaryColor-default cursor-pointer"
            />
          </a-badge>
        </div>

        <template #overlay>
          <div class="bg-white shadow-lg w-[300px] mt-[6px] mr-[10px]">
            <div
              v-if="true"
              class="px-[20px]"
            >
              <a-tabs
                v-model:activeKey="activeTab"
                :tab-bar-gutter="30"
                @change="handleClickTab"
              >
                <a-tab-pane
                  key="unRead"
                  :tab="unReadText"
                >
                  <template v-if="unReadMessageList.length>0">
                    <div
                      v-for="item,index in unReadMessageList"
                      :key="index"
                    >
                      <div class="flex mb-[10px]">
                        <div>
                          <img
                            class="w-[32px] h-[32px] overflow-hidden rounded-full mr-[10px]"
                            :src="item?.user?.avatar"
                          >
                        </div>
                        <div>
                          <div
                            class="text-primaryColor-default cursor-pointer"
                            @click="handleRead(item)"
                          >
                            {{ item?.title }}
                          </div>
                          <div class="text-[#999]">
                            {{ item?.created_at }}
                          </div>
                        </div>
                      </div>
                      <a-divider v-if="index!=unReadMessageList.length-1" />
                    </div>
                  </template>
                  <div
                    v-else
                    class="text-center p-[10px]"
                  >
                    暂无未读消息
                  </div>
                </a-tab-pane>
                <a-tab-pane
                  key="history"
                  tab="历史"
                >
                  <template v-if="historyMessageList.length>0">
                    <div
                      v-for="item,index in historyMessageList"
                      :key="index"
                    >
                      <div class="flex mb-[10px]">
                        <div>
                          <img
                            class="w-[32px] h-[32px] overflow-hidden rounded-full mr-[10px]"
                            :src="item?.user?.avatar"
                          >
                        </div>
                        <div>
                          <div
                            class="text-primaryColor-default cursor-pointer"
                            @click="handleRead(item)"
                          >
                            {{ item?.title }}
                          </div>
                          <div class="text-[#999]">
                            {{ item?.created_at }}
                          </div>
                        </div>
                      </div>
                      <a-divider v-if="index!=historyMessageList.length-1" />
                    </div>
                  </template>
                  <div
                    v-else
                    class="text-center p-[10px]"
                  >
                    暂无未读消息
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
            <div
              v-else
              class="text-center p-[10px]"
            >
              暂无未读消息
            </div>
          </div>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { noticeApi } from '@/api/notice'
import { computed } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { router } from '@/router'
import { Model } from '@/types/model'
const appStore = useAppStore()

const historyMessageList = computed<Model.MessageItem[]>(() => appStore.historyMessageList)
const unReadMessageList = computed<Model.MessageItem[]>(() => appStore.unReadMessageList)
const activeTab = ref<'unRead' | 'history'>('unRead')
const unReadText = computed(() => `未读(${unReadMessageList.value?.length})`)
const handleClickTab = () => {
}
const handleRead = async (item:any) => {
  await noticeApi.addNoticeRecord(item?.id)
  router.push({
    name: 'resumeDetail',
    query: {
      id: item?.content_id
    }
  })
}
</script>
