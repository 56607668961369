<template>
  <div>
    <div
      class="absolute top-[-30px] right-[10px]"
      :style="props?.styleObj"
    >
      {{ props?.tipText }}
    </div>
    <a-input-number
      :precision="2"
      addon-before="￥"
      addon-after="元"
      :value="props.value"
      v-bind="bindAttrs"
      @update:value="handleChange"
    />
  </div>
</template>

<script lang="ts" setup name="input-money-tip">
import { inputMoneyTipProps } from './props'
const props = defineProps(inputMoneyTipProps)
const attrs = useAttrs()
type EmitEvents = {
  (e: 'update:value', value): void
}
const bindAttrs = computed(() => ({
  ...attrs,
  ...props,
}))
const emits = defineEmits<EmitEvents>()

function handleChange(value) {
  emits('update:value', value)
}
</script>
