import { ExtractPropTypes } from 'vue'
import type { SelectProps } from 'ant-design-vue/es/select'
// type SelectorTypes = keyof Reference.SelectorTypes

export const deptSelectProps = {
  apiParams: {
    type: Object,
    default: () => ({}),
  }
}

export type DeptSelectProps = Partial<ExtractPropTypes<typeof deptSelectProps>> & SelectProps