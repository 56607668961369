

/**
 *
 * @param arr 传递给接口的参数数组
 * @param api 请求接口
 * @param handle 处理函数
 * @returns 接口返回的结果数组
 */
export const loopPromise = (arr, api: (params: any) => Promise<Recordable>, handle?:Fn<number, any>) => {
  const apiResArr: Recordable[] = []
  let count = 0
  return new Promise((resolve, reject) => {
    const loopArr = () => {
      try {
        api(arr[count]).then(res => {
          count++
          apiResArr.push(res)
          if (count < arr.length) {
            loopArr()
            handle && handle(Number((count / arr.length * 100).toFixed(2)))
          } else {
            resolve(apiResArr)
            handle && handle(100)
          }
        })
      } catch (err) {
        console.log(err, '===')
      }

    }
    loopArr()

  })


}