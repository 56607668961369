import { ExtractPropTypes } from 'vue'

export const inputCaptchaProps = {
  mobile: {
    type: [String, Function],
    default: ''
  },
  countdown: {
    type: Number,
    default: 60
  },
  getCaptchaApi: {
    type: Function,
  },
  getCaptchaParams: {
    type: Object,
    default: () => ({})
  }
}

export type InputCaptchaProps = Partial<ExtractPropTypes<typeof inputCaptchaProps>>