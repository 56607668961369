<template>
  <custom-api-select
    :api="getUsers"
    :api-params="apiParams"
    :immediate="true"
    value-field="value"
    label-field="label"
  />
</template>
<script lang="ts" setup>
import { useAppStore } from '@/store'
import { usersSelectProps } from './props'
import { cloneDeep } from 'lodash-es'
const props = defineProps(usersSelectProps)
const { reference } = useAppStore()
const { apiParams } = props
const getUsers = () => (new Promise((resolve, reject) => {
  if (reference) {
    let users = cloneDeep(reference.users)
    if (props.needFilter) {
      users = users?.filter(item=>item.status)
    }
    users?.forEach(item => {
      item.label = `${item.label}[${item?.department?.name}]`
    })
    resolve(users)
  } else {
    reject([])
  }
}))
</script>
