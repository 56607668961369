<style lang="less">
.box {
  display: flex;
  justify-content: space-between;

  .ant-input-group {
    width: 100%;
    text-align: left;
  }
}
</style>

<template>
  <div class="w-full box">
    <a-input-group
      compact
      class="w-full"
    >
      <a-input
        v-bind="bindAttrs"
        style="width: calc(100% - 110px);"
        autocomplete="off"
        @update:value="handleChange"
      />
      <a-button
        type="primary"
        class="w-[110px]"
        :disabled="countdown<props.countdown"
        @click="sendCaptcha"
      >
        {{ countdown==props.countdown ?'获取验证码' :`剩余 ${ countdown } 秒` }}
      </a-button>
    </a-input-group>
  </div>
</template>
<script lang="ts" setup>
import { useFormContext } from '../../../hooks/context'
import { useCaptcha } from '@/hooks/captcha'
import { inputCaptchaProps } from './props'
import { isFunction } from 'lodash-es'
import { authApi } from '@/api/auth'
import { useMessage } from '@/hooks/message'

// useFormContext 内部使用了 inject，而 inject 仅能在 setup 顶部调用，故不可在 函数中再调用
// https://v3.cn.vuejs.org/api/composition-api.html#provide-inject
const formContext = useFormContext()
const { captchaCountdown, getCaptcha } = useCaptcha()
const attrs = useAttrs()
const props = defineProps(inputCaptchaProps)
const bindAttrs = computed(() => ({
  ...attrs,
  ...props,
}))
type EmitEvents = {
  (e:'update:value', value):void
}
const emits = defineEmits<EmitEvents>()

/** 倒计时秒数 */
const countdown = ref(props.countdown)

/** 计时器 */
let timer = -1

onBeforeUnmount(() => {
  clearTimeout(timer)
})

// 倒计时函数
function countdownFun() {
  countdown.value = countdown.value - 1
  console.log('countdown', countdown.value)
  if (countdown.value > 0) {
    clearTimeout(timer)
    timer = window.setTimeout(countdownFun, 1000)
  } else {
    countdown.value = props.countdown
  }
}

function handleChange(value) {
  emits('update:value', value)
}

async function sendCaptcha() {
  const mobile = isFunction(props.mobile) ? props.mobile() : props.mobile
  console.log('sendCaptcha:mobile', mobile)
  if (!mobile) {
    useMessage.error('手机号为空')
    return
  }
  if (props.getCaptchaApi) {
    await props.getCaptchaApi(props.getCaptchaParams)
  } else {
    await authApi.getCaptcha({ mobile })
  }
  countdownFun()
}
</script>
