<template>
  <span>
    <!-- 用户筛选 -->
    <contact-picker
      v-if="showContactPicker"
      class="mr-4 min-w-[200px]"
      :value="searchState.type"
      :disableds="contactDisables"
      @change="handleChangeContact"
    />
  </span>
</template>

<script lang="ts" setup>
import { PropType, reactive } from 'vue'
import ContactPicker from '@/components/form/components/ContactPicker/Index.vue'
import { useUserStore } from '@/store/modules/user'

const emits = defineEmits(['changeFilter'])
const props = defineProps({
  defaultType: {
    type: String as PropType<string>,
    default: 'my',
  },
  showContactPicker: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
})

const searchState = reactive<{ type: string, range:string[] }>({
  type: props.defaultType,
  range: [],
})

const contactDisables = ref<string[]>([])

const { user } = useUserStore()
watch(
  () => user?.role,
  () => {
    if (!props.showContactPicker){
      return false
    }
  },
  {
    immediate: true,
  },
)
watch(
  () => [searchState.type],
  () => {
    emits('changeFilter', searchState)
  },
  {
    immediate: true,
  },
)

function handleChangeContact(value) {
  searchState.type = value
}
</script>
