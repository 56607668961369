<template>
  <div class="inline-block">
    <a-dropdown trigger="click">
      <a-input :value="selectedLabel" readonly>
        <template #suffix> <down-outlined class="ant-select-arrow" /> </template>
      </a-input>
      <template #overlay>
        <a-menu
          :selectedKeys="[selected]"
          @click="handleSelect">
          <template v-for="item in items">
            <a-menu-item
              v-if="!disableds.includes(item.value)"
              :key="item.value">
              {{ item.label }}
            </a-menu-item>
          </template>
        </a-menu>
      </template>
    </a-dropdown>
    <teleport to="body">
      <diy
        ref="diyModal"
        @ok="handleDiyOk"/>
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import { isString } from 'lodash-es'
import Diy from './Diy.vue'
import { useAppStore } from '@/store/modules/app'
import { Model } from '@/types/model'

const { reference } = useAppStore()
const items = reference?.reportDataType!

const emits = defineEmits(['update:value', 'change'])
const diyModal = ref()
const selected = ref(items[0].value)
const selectedLabel = ref(items[0].label)
const props = defineProps({
  value: {
    type: [String],
  },
  disableds: {
    type: Array,
    default: () => [],
  },
})

watch(
  () => props.value,
  (value) => {
    const key = isString(value) ? value : value.key
    selected.value = key
    const selectedItem = items.find((i) => i.value === key)
    if (isString(value) && selectedItem) {
      selectedLabel.value = selectedItem?.label
    }
  },
  {
    immediate: true,
  },
)

function handleSelect({ key }) {
  if (key === 'diy') {
    diyModal.value.open()
  } else {
    emits('change', key)
  }
}

function handleDiyOk(data: { users: Model.User[], departments: Model.Department[] }) {
  selected.value = 'diy'
  const label: string[] = []
  if (data.users.length > 0) {
    label.push(data.users.map(u => u.name).join(','))
  }
  if (data.departments.length > 0) {
    label.push(data.departments.map(u => u.title).join(','))
  }
  selectedLabel.value = label.join(',')
  const user_ids = data.users.map(u => u.id)
  const department_ids = data.departments.map((d) => d.id)
  diyModal.value.close()
  emits('change', { user_ids, department_ids })
}
</script>
