/**
 *
 * @param {string} date 需要包装的数据
 * @param {string} pre_and_suffix 需要加上的前后缀 eg:前缀_后缀      默认'* * *_ * * *'
 * @returns {string} 包装好的信息
 */
export const toErrorPackage = (date:string, pre_and_suffix = '***_***') => {
  try {
    const fixArr = pre_and_suffix.split('_')
    if (fixArr.length !== 2) {
      throw new Error('toErrorPackage接收传入的前后缀格式有误，仅接收 "前缀_后缀" 格式的参数')
    }
    return ` ${fixArr[0]}${date}${fixArr[1]}`
  } catch (error) {
    console.warn('包装失效', error)
    return date
  }

}