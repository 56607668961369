
<style scoped>
:deep(.ant-menu-item-icon) {
  font-size: 16px;
}
</style>

<script lang="tsx">
import { defineComponent, ref, h, computed } from 'vue'
import { useRouter, RouteRecordRaw } from 'vue-router'
import BasicIcon from '@/components/icon/basic-icon.vue'
import * as antIcons from '@ant-design/icons-vue'
import { camelCase, upperFirst } from 'lodash-es'
import { listenerRouteChange } from '@/mitt/route-listener'
import { useGlobalCacheStore } from '@/store'
import { useUserStore } from '../../../store/modules/user'


export default defineComponent({
  emit: ['collapse'],
  components: { BasicIcon },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const menus = useUserStore().menus
    let { clearGlobalCacheMap } = useGlobalCacheStore()


    // 获得菜单项配置
    const menuTree = computed(() => {
      const copyRouter = JSON.parse(JSON.stringify(menus))
      function travel(_routes: RouteRecordRaw[], layer: number) {
        if (!_routes) {
          return null
        }
        const collector: any = _routes.map(element => {
          // leaf node
          if (!element.children) {
            return element
          }

          // route filter hideInMenu true
          const children = element.children.filter(x => x.meta?.hideInMenu !== true)
          // 子菜单只有一个时也需要折叠，下面的菜单渲染规则也需要做相应修改
          // if (children.length == 1) {
          //   const meta = {
          //     title: children[0]?.meta?.title,
          //     icon: element.meta?.icon,
          //     showBadge: element.meta?.showBadge,
          //   }
          //   children[0].path = element.path
          //   return {
          //     ...children[0],
          //     meta
          //   }
          // }
          element.children = children

          // Associated child node
          const subItem = travel(element.children, layer)
          if (subItem.length) {
            element.children = subItem
            return element
          }
          // the else logic
          if (layer > 1) {
            element.children = subItem
            return element
          }

          if (element.meta?.hideInMenu === false) {
            return element
          }

          return null
        })
        return collector.filter(Boolean)
      }
      return travel(copyRouter, 0)
    })
    const goto = routeConfig => {
      if (routeConfig.meta?.toPath) {
        window?.open(routeConfig.meta?.toPath)
        return
      }
      selectedKey.value = [routeConfig.name as string]

      /* 从菜单点击都清理掉 */
      clearGlobalCacheMap()
      router.push({ name: routeConfig.name })
    }
    // 监听路由变化，设置当前选中的菜单 key
    const selectedKey = ref<string[]>([route.name as string]) // 当前选中的菜单项名
    const openKeys = ref<any[]>([])
    const openMenuFn = route => {
      const openMenu = menus
        .filter(menu => (menu as RouteRecordRaw).children)
        .find(menu => menu?.children?.some(m => m.name === route.name))
      if (openMenu) {
        openKeys.value = [openMenu.name]
      }
    }
    openMenuFn(route)
    listenerRouteChange(newRoute => {
      if (!newRoute.meta.hideInMenu && newRoute.name) {
        selectedKey.value = [newRoute.name as string]
      }
      openMenuFn(newRoute)
    }, true)

    // 渲染子菜单
    const renderSubMenu = () => {
      function travel(routeConfigList: RouteRecordRaw[], nodes = []) {
        if (routeConfigList) {
          routeConfigList.forEach(routeConfig => {
            const UpperIcon = upperFirst(camelCase(routeConfig.meta?.icon))

            const r = (routeConfig.children && routeConfig.children?.length >= 1) ? (
              <a-sub-menu
                key={routeConfig?.name}
                v-slots={{
                  icon: () => ((routeConfig.meta?.icon) ? h(BasicIcon, { name: routeConfig.meta?.icon }) : h(antIcons[UpperIcon!])),
                  title: () => routeConfig.meta?.title || '',
                }}
              >
                {routeConfig?.children?.map(elem => (
                  <a-menu-item key={elem.name} onClick={() => {
                    goto(elem)
                  }}>
                    {elem.meta?.title || ''}
                    {travel(elem.children ?? [])}
                  </a-menu-item>
                ))}
              </a-sub-menu>
            ) : (
              <a-menu-item
                key={routeConfig.name}
                onClick={() => {
                  goto(routeConfig)
                }}
                v-slots={{
                  icon: () => ((routeConfig.meta?.icon) ? h(BasicIcon, { name: routeConfig.meta?.icon }) : h(antIcons[UpperIcon!])),
                }}
              >
                {routeConfig.meta?.title}
              </a-menu-item>
            )
            nodes.push(r as never)
          })
        }
        return nodes
      }
      return travel(menuTree.value)
    }

    return () => (
      <div style="height: calc(100% - 50px)">
        <a-menu
          selected-keys={selectedKey.value}
          open-keys={openKeys.value}
          style="height: 100%; overflow: auto;"
          mode="inline"
          theme="dark"
        >
          {renderSubMenu()}
        </a-menu>
      </div>
    )
  },
})
</script>