// ant-design 库组件
import Input, { InputProps } from 'ant-design-vue/es/input'
import InputNumber, { InputNumberProps } from 'ant-design-vue/es/input-number'
import TimePicker, { TimePickerProps } from 'ant-design-vue/es/time-picker'
import Switch, { SwitchProps } from 'ant-design-vue/es/switch'
import Radio, { RadioProps } from 'ant-design-vue/es/radio'
import Select, { SelectProps } from 'ant-design-vue/es/select'
import Cascader, { CascaderProps } from 'ant-design-vue/es/cascader'
import Checkbox, { CheckboxProps } from 'ant-design-vue/es/checkbox'

import { DatePickerProps, MonthPickerProps, WeekPickerProps, RangePickerProps } from 'ant-design-vue/es/date-picker'
import { TreeSelectProps } from 'ant-design-vue/es/tree-select'
import Tree, { TreeProps } from 'ant-design-vue/es/tree'
// 若从 es 模块中引用 datePicker，将导致 datePicker 样式丢失，无法正常显示，故在此通过 ant-design-vue 中导入
import { DatePicker, TreeSelect } from 'ant-design-vue'


// 业务无关自定义通用组件
import InputMobile, { InputMobileProps } from './common-components/input-mobile'
import InputMoney, { InputMoneyProps } from './common-components/input-money'
import RegionSelect, { RegionSelectProps } from './common-components/region-select'
import CustomDateSelect from './common-components/custom-date-select'
import PureDisplay, { PureDisplayProps } from './common-components/pure-display'
import CustomApiSelect, { CustomApiSelectProps } from './common-components/custom-api-select'
import InputTextArea, { InputTextAreaProps } from './common-components/input-textarea'
import InputMoneyTip, { InputMoneyTipProps } from './common-components/input-money-tip'

// 业务组件
import Upload, { UploadProps } from './business-components/upload'
import UploadDragger, { UploadDraggerProps } from './business-components/upload-dragger'
import InputCaptcha, { InputCaptchaProps } from './business-components/input-captcha'
import ReferenceSelect, { ReferenceSelectProps } from './business-components/reference-select'
// import SocialTableForm, { SocialTableFormProps } from './business-components/social-table-form'
import ExcelImport, { ExcelImportProps } from './business-components/excel-import'
import WangEditor, { WangEditorProps } from './business-components/wang-editor'
import ResumeCategoryApiSelect, { ResumeCategoryApiSelectProps } from './business-components/resume-category-select'
import DeptSelect, { DeptSelectProps } from './business-components/dept-select'
import UsersSelect, { UsersSelectProps } from './business-components/users-select'
import DeptUserFilter from './../../../components/DeptUserFilter.vue'
import DateFilter from './../../../components/DateFilter.vue'

export const componentTypeMap = {
  Input,
  InputPassword: Input.Password,
  InputSearch: Input.Search,
  InputNumber,
  Cascader,
  Select,
  DatePicker,
  MonthPicker: DatePicker.MonthPicker,
  WeekPicker: DatePicker.WeekPicker,
  DateRangePicker: DatePicker.RangePicker,
  TimePicker,
  Switch,
  Radio,
  RadioGroup: Radio.Group,
  Checkbox,
  CheckboxGroup: Checkbox.Group,
  TreeSelect,
  Tree,

  // 自定义通用组件
  InputMobile,
  InputMoney,
  RegionSelect,
  PureDisplay,
  CustomApiSelect,
  InputTextArea,
  InputMoneyTip,
  CustomDateSelect,

  // 业务组件，调用特定业务接口
  Upload,
  UploadDragger,
  InputCaptcha,
  ReferenceSelect,
  // SocialTableForm,
  ExcelImport,
  WangEditor,
  ResumeCategoryApiSelect,
  DeptSelect,
  UsersSelect,
  DeptUserFilter,
  DateFilter
}

export type ComponentType = keyof typeof componentTypeMap |

  /** 页面传入的自定义组件 */
  'Custom'


export type ComponentProp<T extends ComponentType> = {
  'Input': InputProps,
  'InputPassword': InputProps,
  'InputSearch': InputProps,
  'InputNumber': InputNumberProps,
  'DatePicker': DatePickerProps,
  'MonthPicker': MonthPickerProps,
  'WeekPicker': WeekPickerProps,
  'DateRangePicker': RangePickerProps,
  'TimePicker': TimePickerProps,
  'Switch': SwitchProps,
  'Radio': RadioProps,
  'Select': SelectProps,
  'Cascader': CascaderProps,
  'Checkbox': CheckboxProps,
  'TreeSelect': TreeSelectProps,
  'Tree': TreeProps,

  // 自定义通用组件
  'CustomApiSelect': CustomApiSelectProps,
  'InputMobile': InputMobileProps,
  'InputMoney': InputMoneyProps,
  'RegionSelect': RegionSelectProps,
  'InputTextArea': InputTextAreaProps,
  'PureDisplay': PureDisplayProps,
  'InputMoneyTip': InputMoneyTipProps,


  // 业务组件，调用特定业务接口
  'Upload': UploadProps,
  'UploadDragger':UploadDraggerProps,
  'InputCaptcha': InputCaptchaProps,
  'ReferenceSelect': ReferenceSelectProps,
  'ExcelImport': ExcelImportProps,
  'WangEditor': WangEditorProps,
  'ResumeCategoryApiSelect': ResumeCategoryApiSelectProps,
  'DeptSelect': DeptSelectProps,
  'UsersSelect':UsersSelectProps,
  [K: string]: any,
}[T]