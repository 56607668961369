import { createPinia } from 'pinia'
import { App } from 'vue'
import { useAppStore } from './modules/app'
import { useUserStore } from './modules/user'
import { keepAliveStore } from './modules/keep-alive'
import { useGlobalCacheStore } from './modules/global-cache'

const store = createPinia()

// 使用状态管理包
export function setupStore(app: App<Element>) {
  app.use(store)
}

function useUserWithoutStore() {
  return useUserStore(store)
}

export { store, useAppStore, useUserStore, keepAliveStore, useUserWithoutStore, useGlobalCacheStore }
