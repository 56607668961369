<template>
  <a-dropdown
    placement="bottomLeft"
    trigger="hover"
  >
    <div class="px-2 cursor-pointer">
      <!-- <a-avatar
        v-if="userStore.user?.avatar"
        :size="46"
        :src="userStore.user?.avatar"
      /> -->
      <span class="mx-2">{{ userStore.user?.name }}</span>
      <caret-down-outlined class="text-[#000000d9]" />
    </div>
    <template #overlay>
      <a-menu @click="handleClickUserMenu">
        <a-menu-item
          key="personInfo"
        >
          <div class="flex items-center">
            <span class="ml-2">个人信息</span>
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item
          key="updatePwd"
        >
          <div class="flex items-center">
            <span class="ml-2">修改密码</span>
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item
          key="bindEmail"
        >
          <div class="flex items-center">
            <span class="ml-2">邮箱绑定</span>
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item key="logout">
          <div class="flex items-center">
            <!-- <logout-outlined /> -->
            <span class="ml-2">退出登录</span>
          </div>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script lang="ts" setup>
import { Modal } from 'ant-design-vue'
import { useUserStore } from '@/store'
import { router } from '@/router'
defineProps({
  isIndex: {
    type: Boolean,
    default: false
  }
})
const userStore = useUserStore()
function handleClickUserMenu({ key }) {
  const keys = ['personInfo', 'updatePwd', 'bindEmail', 'message']
  if (keys.includes(key)) {
    router.push({
      name: 'my',
      query: { key }
    })
  } else if (key === 'logout') {
    Modal.confirm({
      title: '提醒',
      content: '确认退出登录吗？',
      onOk: async () => {
        await userStore.logout()
      },
    })
  }
}
</script>
