<template>
  <a-config-provider
    :locale="zhCN"
    :transform-cell-text="({ text }) => text ? text : '--'"
  >
    <div>
      <router-view v-if="isRouteActive" />
      <a-spin v-else />
      <modal-form
        v-if="globalModelForm._isRender"
        v-bind="(globalModelForm.modelFormAttr as Readonly<ExtractPropTypes<typeof modalFormProps>>)"
        :ref="el => globalModelForm.modelFromRef = el"
        :visible="globalModelForm.visible"
        @ok="() => globalModelForm.visible = false"
        @cancel="() => globalModelForm.visible = false"
      />
    </div>
  </a-config-provider>
</template>
<script lang="ts" setup>
import type { ExtractPropTypes } from 'vue'
import { modalFormProps } from '@/components/form/props'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import ModalForm from './components/form/modal-form.vue'
import globalModelForm from '@/hooks/global-model-form'
// eslint-disable-next-line no-duplicate-imports
import { provide, nextTick } from 'vue'
const isRouteActive = ref(true)
provide('refresh', () => {
  isRouteActive.value = false
  nextTick(() => {
    isRouteActive.value = true
  })
})
</script>