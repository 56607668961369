import { request } from '@/utils/request'

export const noticeApi = {
  getFresh: () => request.get('/notice/fresh'),
  // 查询消息列表
  getNoticeList: data => request.post('/notice/page/query', data),
  // 消息列表
  getNoticeUnreadList: data => request.get('/notices', data),
  // 查询个人消息列表
  getPersonNoticeList: data => request.post('/notice/receive/page/query', data),
  // 添加消息
  addNotice: data => request.post('/notice/store', data),
  // 标记已读
  addNoticeRecord: id => request.post('/notice/read/', { id }),
  // 修改消息
  updateNotice: data => request.post('/notice/update', data),
  // 删除消息
  deleteNotice: id => request.get(`/notice/destroy/${id}`),
  // 获取通知详情
  getNoticeDetail: id => request.get(`/notice/detail/${id}`),
  // 发送消息
  sendNotice: id => request.get(`/notice/send/${id}`)

}
