<template>
  <div>
    <a-upload
      v-bind="bindAttrs"
      :before-upload="handleBeforeUpload"
      @change="handleChange"
      @preview="handlePreview"
    >
      <slot>
        <div v-if="!slots.default && filePaths.length < maxNum && files?.length < maxNum">
          <template v-if="props.acceptType === 'img'">
            <upload-outlined />
            <div class="ant-upload-text">
              {{ props.uploadBtnText }}
            </div>
          </template>
          <template v-else>
            <a-button
              :type="props.buttonType"
              :ghost="props.ghost"
            >
              <upload-outlined /> {{ props.uploadBtnText }}
            </a-button>
            <a-button
              v-if="!autoUpload"
              type="primary"
              :disabled="files?.length === 0"
              :loading="uploading"
              class="ml-3"
              @click="handleUpload"
            >
              {{ uploading ? '上传中' : '开始上传' }}{{ `${schedule}%` }}
            </a-button>
          </template>
        </div>
      </slot>
      <!-- 隐藏上传列表，这里其实就是自定义上传列表，并设置为空的 -->
      <template
        v-if="props.hideUploadList"
        #itemRender="{}"
      >
        <div />
      </template>
    </a-upload>
    <div
      v-if="props.remindInfo"
      class="text-gray-200"
    >
      {{ props.remindInfo }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRaw, unref, useAttrs, useSlots } from 'vue'
import { useMessage } from '@/hooks/message'
import { UploadOutlined } from '@ant-design/icons-vue'
import { useEnv } from '@/hooks/env'
import { useUserStore } from '@/store'
import { previewFromUrl } from '@/utils/file'
import { pick, omit, isObject, isArray, isFunction, isString } from 'lodash-es'
import type { UploadFile, UploadProps } from 'ant-design-vue'
import { uploadProps } from './props'
import { getFileName } from '@/utils/qs'
import imageCompression from 'browser-image-compression'
import { FileType } from 'ant-design-vue/es/upload/interface'
import { loopPromise } from '@/utils/loop-promise'
import { globalApi } from '@/api/global'

const attrs = useAttrs()
const slots = useSlots()
type EmitEvents = {
  (e: 'update:value', filePaths: Array<string > | string):void,
  (e: 'OriginResponseValue', files: Array<any>): void,
}
const emits = defineEmits<EmitEvents>()
const props = defineProps(uploadProps)
const uploading = ref<boolean>(false)
const schedule = ref<number>(0)
// 文件路径数组
const filePaths = ref<string[]>([])
const files = ref<UploadProps['fileList']>([])

const handleUpload = async (e: Event) => {
  e.stopPropagation()
  uploading.value = true

  const res = await loopPromise(files.value, params => globalApi.globalUpLoad({
    type: params.type,
    file: params.originFileObj
  }), percent => {
    schedule.value = percent
  }) as any
  if (props.valueIsId) {
    emits('update:value', attrs.multiple ? res.map(i => i.id) : res[0]?.id)
  } else {
    emits('update:value', attrs.multiple ? res.map(i => i.path) : res[0]?.path)
  }
  emits('OriginResponseValue', res)
  files.value = []
  uploading.value = false


}
// upload 组件值，设置该值，使得 upload 组件为受控组件。上传的状态及图片的显示，由 files 控制
// 目前格式是字符串数组图片链接
onMounted(() => {

  if (isArray(props.value)) {
    files.value = props.value.map(fileUrl => {
      const fileName = getFileName(fileUrl)
      return {
        uid: fileName,
        name: fileName,
        status: 'done',
        url: fileUrl,
      }
    })
  } else {
    const fileName = getFileName(props.value)
    files.value = [
      {
        uid: fileName,
        name: fileName,
        status: 'done',
        url: props.value,
      }
    ]
  }

})


watch(() => props.value, val => {
  const urlList: string[] = []
  let vals: any = []
  if (isString(val)) {
    console.log('val', val)
    const fileName = getFileName(val)
    urlList.push(val)
    vals = [
      {
        uid: fileName,
        name: fileName,
        status: 'done',
        url: val
      }
    ]
  }
  if (isArray(val)) {
    vals = val?.filter(item => isObject(item)).map((item: any) => {
      const fileName = getFileName(item.img)
      urlList.push(item.img)
      return {
        uid: fileName,
        name: fileName,
        status: 'done',
        url: item.img,
      }
    })
  }
  if (isArray(urlList) && urlList.length > 0) {
    emits('update:value', attrs.multiple ? urlList : urlList[0])
  }
  if (vals?.length > 0) {
    files.value = vals
  }
  // 重置预览图片
  if (isArray(val) && val.length === 0) {
    files.value = []
  }
})


// 允许上传的文件类型
const getAccept = computed(() => {
  if (props.accept.length) {
    return props.accept
  }
  if (props.acceptType === 'img') {
    return ['jpg', 'jpeg', 'png']
  }
  if (props.acceptType === 'zip') {
    return ['zip']
  }
  if (props.acceptType === 'doc') {
    return ['doc', 'docx']
  }
  if (props.acceptType === 'all') {
    return ['jpg', 'jpeg', 'png', 'zip', 'doc', 'docx', 'xls', 'xlsx', 'pdf']
  }
  return ['xls', 'xlsx', 'pdf']
})

// 获取上传接口调用的请求头
const getHeaders = computed(() => ({
  ...pick(attrs, 'headers'),
  Cookie: `token=${useUserStore().token}`,
}))

const getParams = computed(() => ({
  ...pick(attrs, 'data'),
  type: props.acceptType,
}))

const bindAttrs = computed(() => ({
  fileList: unref(files),
  // action: useEnv.uploadApiUrl,
  action: useEnv.baseApiUrl + props.uploadUrl,
  headers: unref(getHeaders),
  data: unref(getParams),
  accept: unref(getAccept)
    .map(i => (i.startsWith('.') ? i : `.${i}`))
    .join(','),
  ...(props.acceptType === 'img' ? { 'list-type': 'picture-card' } : {}),
  ...omit(attrs, ['headers', 'data']),
}))
// 上传前拦截
async function handleBeforeUpload(file: FileType, FileList: FileType[]) {
  const { size, name } = file
  const { maxSize, maxNum } = props
  if (maxSize && maxSize * 1024 * 1024 < size) {
    useMessage.error(`只能上传不超过${maxSize}M的文件`)
    return false
  }
  console.log(FileList.length, maxNum)

  if (maxNum && maxNum < FileList.length) {
    useMessage.error(`最多上传${maxNum}个文件`)
    return false
  }

  const accept = unref(getAccept)
  if (accept.length > 0 && !new RegExp(`\\.(${accept.join('|')})$`, 'i').test(name)) {
    useMessage.error(`只能上传${accept.join(',')}格式的文件`)
    return false
  }
  if (props.acceptType === 'img') {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      initialQuality: 0.6
    }
    const compressedFile = await imageCompression(file, options)
    return Promise.resolve(compressedFile)
  }
  return props.autoUpload
}

// 上传完成，数据整理
function handleChange({ file, fileList }: { file: UploadFile, fileList: UploadFile[] }) {
  const doneFileList = toRaw(fileList)
    .filter(f => f.status === 'done')
  if (file.size && file.size > props?.maxSize * 1024 * 1024) {
    return
  }

  // 需要在这里及之前handleChangeFn，否则files.value.length !== doneFileList.length的判断会使得所有文件上传万才会调用handleChangeFn
  if (file.status === 'done') {
    if (isFunction(props.handleChangeFn) && file.status === 'done') {
      props.handleChangeFn(doneFileList, file)
    }
  }
  files.value = fileList // 设置为受控组件，需要将 uploading 状态的 file 同步到 files 中，使的上传功能继续执行，才能触发 done 等上传结束事件
  if (file.status !== 'done' && file.status !== 'removed' || files.value.length !== doneFileList.length) {
    return
  }

  filePaths.value = doneFileList
    .map(f => {
      if (f.response) { // 新上传的文件
        return f.response.data?.url ?? f.response.data?.path ?? f.response.data
      } else { // 数据回填的文件
        return new URL(f.url!).pathname
      }
    })
  const fileIds = doneFileList
    .map(f => f.response.data?.id)
  const originResponseValue:UploadFile[] = doneFileList
    .map(f => f?.response?.data)
  if (props.valueIsId) {
    emits('update:value', attrs.multiple ? fileIds : fileIds[0])
  } else {
    emits('update:value', attrs.multiple ? filePaths.value : filePaths.value[0])
  }
  emits('OriginResponseValue', originResponseValue)
}

// 预览功能，解决 ant-design 默认新开页面，打开 base64 数据造成的 chrome 安全警告问题，Not allowed to navigate top frame to data URL:
function handlePreview(file: UploadFile) {
  if (file.response) { // 新上传的文件
    previewFromUrl(file.response.data?.url)
  } else { // 数据回填的文件
    previewFromUrl(file.url!)
  }
}
</script>
