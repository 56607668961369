<template>
  <custom-api-select
    :api="resumeCategoryApi.getResumeCategoryListApi"
    :api-params="apiParams"
    :immediate="true"
    value-field="id"
    label-field="name"
  />
</template>
<script lang="ts" setup>
import { resumeCategoryApi } from '@/api/resume-category'
import { resumeCategoryApiSelectProps } from './props'
const props = defineProps(resumeCategoryApiSelectProps)
const { apiParams } = props
</script>
