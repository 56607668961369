import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Router } from 'vue-router'
import { useUserWithoutStore } from '@/store'

NProgress.configure({ showSpinner: false })
let menuTree: any[] = []
// 设置路由守卫，进入每个路由前的操作
export default function setupPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    async function gologin() {
      if (to.name == 'notFound') {
        next('/login')
      } else {
        next()
      }
      NProgress.done()
    }

    NProgress.start()
    const userStore = useUserWithoutStore()
    const isLogin = computed(() => userStore.isLogin)

    // 未登录，跳转到登录页
    if (!isLogin.value) {
      gologin()
      return
    }

    if (userStore.user) {
      next()
    } else {
      try {
        await userStore.getUserInfo()
        if (to.path === '/home') {
          next('/workbench')
        } else {
          next({ path: to.path, query: to.query })
        }
      } catch (error) {
        console.log(error, 'error')
        gologin()
      }
    }
    NProgress.done()
  })
}
