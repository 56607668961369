import { PropType, ExtractPropTypes } from 'vue'

export const wangEditorProps = {
  value: String as PropType<string>,
  height: Number as PropType<number>,
  urlFieldPath: {
    type: String,
    default: 'url'
  }
}

export type WangEditorProps = Partial<ExtractPropTypes<typeof wangEditorProps>>