<template>
  <a-select v-bind="bindAttrs" />
</template>
<script lang="ts" setup>
import { computed, unref, PropType, useAttrs } from 'vue'
import { useAppStore } from '@/store/modules/app'

import { get } from 'lodash-es'

const props = defineProps({
  value: [Number, String, Array] as PropType<any>,
  referenceKey: String,
  filter: {
    type: Object as PropType<Recordable>,
  },
  valueField: {
    type: String as PropType<string>,
    default: 'value',
  },
  labelField: {
    type: String as PropType<string>,
    default: 'label',
  },
  zeroToUndefined: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  numberToString: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})
const emits = defineEmits(['blur', 'change', 'update:value'])
const attrs = useAttrs()
if (props.value === 0 && props.zeroToUndefined) {
  emits('update:value',undefined)
}
// const value = computed(() => (props.value === 0 && props.zeroToUndefined ? undefined : props.value))
const value = computed(() => {
  if(props.value === 0 && props.zeroToUndefined) {
    return undefined
  } else {
    return props.value
  }
})

const getOptions = computed(() => {
  const { reference } = useAppStore()
  const { filter, referenceKey, numberToString, valueField, labelField } = props
  let optionData = (attrs.options ?? get(reference, referenceKey!)) as Recordable
  if (filter) {
    optionData = optionData?.filter((i) => {
      const rs = Object.keys(filter).reduce((flag, key) => {
        return flag && i[key] === filter[key]
      }, true)
      return rs
    })
  }
  const options = optionData?.map((item) => {
    const value = item[valueField]
    return {
      label: item[labelField],
      value: numberToString ? `${value}` : value,
    }
  })
  return options
})

const bindAttrs = computed(() => {
  return {
    dropdownStyle: { maxHeight: '280px' },
    optionFilterProp: 'label',
    showSearch: true,
    ...attrs,
    value: unref(value),
    options: unref(getOptions),
    onChange: (...value) => emits('change', ...value),
    'onUpdate:value': (...value) => emits('update:value', ...value),
  }
})
</script>
