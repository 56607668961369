import { useMessage } from '@/hooks/message'

/**
 *
 * @param {Recordable<any>[]} catchList 需要检查的列表数据
 * @param {()=>void} successCallback  // 通过检查执行的函数
 */
export const catchData = (catchList, successCallback) => {
  try {
    if (catchList.length === 0) {
      useMessage.error('没有通过校验的信息，请核对')
    } else {
      successCallback()
    }
  } catch (error) {
    useMessage.error('表格数据不正确，请重新检查')
  }
}