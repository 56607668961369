import { isArray } from 'lodash-es'
import { defineStore } from 'pinia'
import { RouteRecordName } from 'vue-router'

interface KeepAliveState {
    caches: string []
}
export const keepAliveStore = defineStore('KeepAlive', {
  state: (): KeepAliveState => ({
    caches: []
  }),
  getters: {},
  actions: {
    // setupKeepAlive(router:Router) {
    //   router.beforeEach((to, form, next) => {
    //     console.log(to, 'to')

    //     if (to.meta.keepAlive) {
    //       this.addCache(to.name as string)
    //     }
    //     next()
    //   })
    // },
    addCache(cacheName: string) {
      if (this.caches.includes(cacheName)) {
        return
      }
      this.caches.push(cacheName)
    },
    removeCache(cacheName: RouteRecordName | RouteRecordName[]) {
      if (isArray(cacheName)) {
        this.caches = this.caches.filter(cache => !cacheName.includes(cache))
      } else {
        this.caches = this.caches.filter(cache => cache !== cacheName)
      }
    },
    clearCache() {
      this.caches = []
    },

  }
})